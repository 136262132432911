import React from 'react';
/* TODO: Add link to linkedin account */
export default function Contact() {
    return (
        <div className="container-fluid text-center">
            <h3 className="h3 text-primary mb-4">Contact me</h3>
            <p>
                Want to work with me on some project?
                <br/>
                Need some IT support?
                <br/>
                Any questions?
            </p>
            <p>
                Drop me an email at <a className="text-decoration-none" href="mailto:huet.joachim@ik.me">huet.joachim@ik.me</a>
                <br/>
                It will be a pleasure to hear from you.
                </p>
        </div>
    );
}