import { useRef } from 'react';
import linkedin from './linkedin.png';
import gitlab from './gitlab.png';
import phone from './phone.png';
import envelope from './envelope.png';
import globe from './globe.png';
import mapMarker from './map-marker-alt.png';
import html2pdf from 'html2pdf.js';
import image from './joachim.jpg';
import Header from '../Header/header';

const Cv = () => {

    const colorPrimary = '#e64a19';

    const paddingX = '15mm';
    const paddingY = '15mm';

    const a4PageRef = useRef(null);

    const a4PageStyle = {
        background: 'white',
        width: '210mm',
        height: '296mm',
        margin: 0,
        padding: 0,
    }

    const headerStyle = {
        background: colorPrimary,
        paddingTop: paddingY,
        paddingLeft: paddingX,
        paddingRight: paddingX,
        height: "70mm"
    }

    const contentStyle = {
        paddingLeft: paddingX,
        paddingRight: paddingX,
    }

    const options = {
        filename: 'joachim-huet-cv.pdf',
        image: { type: 'jpeg', quality: 0.93 },
        html2canvas: {
            scale: 4,
            scrollX: -window.scrollX,
            scrollY: -window.scrollY,
            logging: false
        },
        jsPDF: { 
            unit: 'mm', 
            format: 'a4', 
            orientation: 'portrait'
        }
    }
    const downloadAsPDF = (e) => {
        console.log("Downloading...");

        document.documentElement.classList.add("hide-scrollbar");
        html2pdf().set(options).from(a4PageRef.current).save().then(() => {
            // make scrollable again
            document.documentElement.classList.remove("hide-scrollbar");
        });
    }

    return (
        <>
            <div className="container-fluid w-100 m-0 p-0">
                <div className="container-fluid max-width-840 w-100 h-100 d-flex flex-column">
                    <Header />
                </div>
            </div>
            <div className="d-flex flex-column mt-2 pt-2 pb-5 align-items-center">
                <div style={{margin: 'auto'}}>
                    <div className="d-flex justify-content-center my-4">
                        <button style={{boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)'}} className="btn btn-primary text-white" onClick={downloadAsPDF}>Download as PDF</button>
                    </div>
                    <div style={{boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)', margin: '16px'}}>
                        <div ref={a4PageRef} id='a4Page' style={a4PageStyle}> 
                            <div className="m-0 w-100 row text-white" style={headerStyle}>
                                <div className="col-8 m-0 p-0">
                                    <h2 className="mb-4">Joachim Huet</h2>
                                    <div className="row">
                                        <div className="col-6">
                                            <p className="d-block my-2"><img alt="Phone" width='16' className="me-3" src={phone} /> +41 (0)79 477 31 52</p>
                                            <a href="mailto:huet.joachim@ik.me" className="d-block text-decoration-none text-reset my-2"><img alt="email" width='16' className="me-3" src={envelope} /> huet.joachim@ik.me</a>
                                            <p className="d-block my-2"><img alt="location" width='16' className="me-3" src={mapMarker} /> Gland, Switzerland</p>
                                        </div>
                                        <div className="col-6 align-items-middle">
                                            <a href="https://johuet.ch" className="d-block text-decoration-none text-reset my-2"><img alt="website" width='16' className="me-3" src={globe} /> johuet.ch</a>
                                            <a href="https://www.linkedin.com/in/joachim-huet/" className="d-block text-decoration-none text-reset my-2"><img alt="linkedin" width='16' className="me-3" src={linkedin} /> joachim-huet</a>
                                            <a href="https://gitlab.com/huet.joachim" className="d-block text-decoration-none text-reset my-2"><img alt="gitlab" width='16' className="me-3" src={gitlab} /> huet.joachim</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4 m-0 justify-content-center">
                                    <img alt="Joachim" className="rounded-circle w-100" src={image} style={{marginTop: '-6mm'}} />
                                </div>
                            </div>
                            <div className="m-0 pt-4 w-100" style={contentStyle}>
                                <div className="row">
                                    <div className="col-8">
                                        <div> {/* Experience */}
                                            <h3 className="text-primary">Experience</h3>
                                            <div> {/* BCV */}
                                                <div className="row fw-bold pt-2">
                                                    <div className="col-4">
                                                        <p className="text-primary mb-1">2019 - Present</p>
                                                    </div>
                                                    <div className="col-8">
                                                        <p className="mb-1">Banque Cantonale Vaudoise</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-1"></div>
                                                    <div className="col-3">
                                                        <p className="text-primary mb-0">2021</p>
                                                    </div>
                                                    <div className="col-8">
                                                        <p className="mb-0">Financial analyst developer</p>
                                                    </div>
                                                    <p className="text-center mb-2">Analyst and all-rounder developer, in the financial transactions traffic team. Lead developer on some applications.</p>
                                                </div>
                                                <div className="row">
                                                    <div className="col-1"></div>
                                                    <div className="col-3">
                                                        <p className="text-primary mb-0">2019</p>
                                                    </div>
                                                    <div className="col-8">
                                                        <p className="mb-0">Junior software engineer</p>
                                                    </div>
                                                    <p className="text-center">All rounder developer in a Swiss universal bank. Particularly in the financial transactions traffic team.</p>
                                                </div>
                                            </div>
                                            <div> {/* Baabuk */}
                                                <div className="row fw-bold pt-2">
                                                    <div className="col-4">
                                                        <p className="text-primary mb-1">2017 - 2019</p>
                                                    </div>
                                                    <div className="col-8">
                                                        <p className="mb-1">Baabuk - Research & Development</p>
                                                    </div>
                                                </div>
                                                <p className="text-center">Master thesis project during an internship at Baabuk about measuring the shoe size a customer should buy with a smartphone. And worked there for a year.</p>
                                            </div>
                                            <div> {/* EPFL */}
                                                <div className="row fw-bold pt-2">
                                                    <div className="col-4">
                                                        <p className="text-primary mb-1">2016</p>
                                                    </div>
                                                    <div className="col-8">
                                                        <p className="mb-1">EPFL - Teaching Assistant</p>
                                                    </div>
                                                </div>
                                                <p className="text-center">Teaching assistant for first-year students at EPFL.</p>
                                            </div>
                                            <div> {/* FutureKids */}
                                                <div className="row fw-bold pt-2">
                                                    <div className="col-4">
                                                        <p className="text-primary mb-1">2015 - 2016</p>
                                                    </div>
                                                    <div className="col-8">
                                                        <p className="mb-1">Futurekids - IT teacher for kids</p>
                                                    </div>
                                                </div>
                                                <p className="text-center">Computer science and robotics teacher for kids.</p>
                                            </div>
                                        </div>
                                        <div> {/* Education */}
                                            <h3 className="text-primary mt-4">Education</h3>
                                            <div> {/* EPFL */}
                                                <div className="row fw-bold pt-2">
                                                    <div className="col-3">
                                                        <p className="text-primary mb-1">2012 - 2018</p>
                                                    </div>
                                                    <div className="col-9">
                                                        <p className="mb-1">École Polytechnique Fédérale de Lausanne</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-1"></div>
                                                    <div className="col-3">
                                                        <p className="text-primary mb-0">2018</p>
                                                    </div>
                                                    <div className="col-8">
                                                        <p className="mb-0">Master degree</p>
                                                    </div>
                                                    <p className="text-center mb-2">Master of Science (Msc) in Computer Science. With a minor in image, video and sound processing.</p>
                                                </div>
                                                <div className="row">
                                                    <div className="col-1"></div>
                                                    <div className="col-3">
                                                        <p className="text-primary mb-0">2016</p>
                                                    </div>
                                                    <div className="col-8">
                                                        <p className="mb-0">Bachelor degree</p>
                                                    </div>
                                                    <p className="text-center">Bachelor in Computer Science. Bachelor thesis titled: The state of the art of Shadow Mapping.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div> {/* Summary */}
                                            <h4 className="text-center text-primary">In a few words</h4>
                                            <p className="text-center">Highly motivated, problem solving oriented, team player, enjoying to learn, loving feedback culture.</p>  
                                        </div>
                                        <div> {/* Skills */}
                                            <h4 className="text-center text-primary mt-4">Skills</h4>
                                            <p className="text-center">Problem solver, Fast learner, Java, Python, Javascript, React, HTML, CSS, Windows, Linux, Amazon Web Services Tools, Google Cloud Computing Services, SQL, NoSql</p>
                                        </div>
                                        <div> {/* Languages */}
                                            <h4 className="text-center text-primary mt-4">Languages</h4>
                                            <div className="row">
                                                <p className="mb-0 ps-4 col-6">French:</p><p className="col-6 mb-0 text-end pe-4 fst-italic">Native</p>
                                                <p className="mb-0 ps-4 col-6">English:</p><p className="col-6 mb-0 text-end pe-4 fst-italic">Fluent</p>
                                                <p className="ps-4 col-6">Spanish:</p><p className="col-6 mb-0 text-end pe-4 fst-italic">Basics</p>
                                            </div>
                                        </div>
                                        <div> {/* Other Trainings */}
                                            <h4 className="text-center text-primary mt-4">Other Trainings</h4>
                                            <p>Business Concept Course (InnoSuisse): <span className="fst-italic">Startup training for founders, Entrepreunarial thinking</span></p>
                                            <p>Tricentis Tosca (Automated continuous testing): <span className="fst-italic">Automation Engineer I, Automation Specialist I/II, Test Design Specialist I/II</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Cv;
