import React from 'react';
import RoutingPage from './components/RoutingPage';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import * as ROUTES from './constants/routes';
import Cv from './components/Cv';

function App() {
  
  return (
    <Router>
      <Route path="/">
        <Switch>
          <Route exact path={ROUTES.CV} component={Cv} />
          <Route path="/" component={RoutingPage} />
        </Switch>
      </Route>
    </Router>
  );
}

export default App;
