import React from 'react';
import chevronCompactDown from './images/chevron-compact-down.svg';
import logoBcv from './images/logo-bcv.png';
import logoBaabuk from './images/logo-baabuk.png';
import logoFuturekids from './images/logo-futurekids.png';
import logoEpfl from './images/logo-epfl.png';
import logoCcm from './images/logo-ccm.png';
import logoNtbc from './images/logo-ntbc.png';
import logoKtv from './images/logo-ktv.png';
import logoJohuet from './images/logo-johuet.png';

export default function Career() {

    const currentYear = new Date().getFullYear();

    return (
        <div className="container-fluid text-center ">
            <h3 className="h3 text-primary mb-4">My curriculum</h3>
            <p className="lead">Here follows my digital CV, if you want you can also download a standard pdf version by heading <a className="text-decoration-none" href="/cv">there</a></p>

            <div className="container-fluid py-4 my-4">
                <div className="row">
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        <h4 className="h4 fw-bold text-primary">{"2019 - " + currentYear}</h4>
                        <button className="btn m-0 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#bcv" aria-expanded="false" aria-controls="bcv">
                            <img alt="See more" src={chevronCompactDown}></img>
                        </button>
                    </div>
                    <div className="col mb-4">
                        <a href="https://bcv.ch"><img className="border-0 mb-1" width="80" alt="Logo BCV" src={logoBcv}></img></a>
                    </div>
                </div>
                <div className="collapse" id="bcv">
                    <div className="container-fluid d-flex flex-column align-items-center">
                        <div className="row border-start border-primary border-3 my-2">
                            <div className="col">
                                {currentYear === 2021 &&
                                    <h5 className="h5 fw-bold">2021</h5>
                                }
                                {currentYear !== 2021 &&
                                    <h5 className="h5 fw-bold">{"2021 - " + currentYear}</h5>
                                }
                            </div>
                            <div className="col">
                                <h5 className="h5">Software Engineer & Analyst Developer</h5>
                            </div>
                            <p>That's my current job, an analyst and all-rounder developer in the <em>Transactions Traffic</em> team.</p>
                        </div>
                        <div className="row border-end border-primary border-3 my-2">
                            <div className="col">
                                <h5 className="h5 fw-bold">2019 - 2020</h5>
                            </div>
                            <div className="col">
                                <h5 className="h5">Junior Software Engineer</h5>
                            </div>
                            <p>Worked as a Junior in a swiss universal bank in the <em>Transactions Traffic</em> part as an all-rounder developer.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-4 my-4">
                <div className="row">
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        <h4 className="h4 fw-bold text-primary">2017 - 2019</h4>
                        <button className="btn m-0 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#baabuk" aria-expanded="false" aria-controls="baabuk">
                            <img alt="See more" src={chevronCompactDown}></img>
                        </button>
                    </div>
                    <div className="col mb-4">
                        <a href="https://baabuk.ch"><img className="border-0 mb-1" width="70" alt="Logo Baabuk" src={logoBaabuk}></img></a>
                    </div>
                </div>
                <div className="collapse" id="baabuk">
                    <div className="container-fluid d-flex flex-column align-items-center">
                        <div className="row border-start border-primary border-3 my-2">
                            <div className="col">
                                <h5 className="h5 fw-bold">2018 - 2019</h5>
                            </div>
                            <div className="col">
                                <h5 className="h5">Research & Development</h5>
                            </div>
                            <p>Continued to research and develop further the product introduced by my master thesis project.</p>
                        </div>
                        <div className="row border-end border-primary border-3 my-2">
                            <div className="col">
                                <h5 className="h5 fw-bold">2017 - 2018</h5>
                            </div>
                            <div className="col">
                                <h5 className="h5">Master Thesis</h5>
                            </div>
                            <p>Did my Master Thesis project during an internship at Baabuk about predicting the shoe size a customer should buy with a smartphone.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-4 my-4">
                <div className="row">
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        <h4 className="h4 fw-bold text-primary">2015 - 2016</h4>
                        <button className="btn m-0 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#futurekids" aria-expanded="false" aria-controls="futurekids">
                            <img alt="See more" src={chevronCompactDown}></img>
                        </button>
                    </div>
                    <div className="col mb-4">
                        <a href="https://futurekids.io"><img className="border-0 mb-2" width="80" alt="Logo Futurekids" src={logoFuturekids}></img></a>
                    </div>
                </div>
                <div className="collapse" id="futurekids">
                    <div className="container-fluid d-flex flex-column align-items-center">
                        <div className="row border-start border-primary border-3 my-2">
                            <div className="col">
                                <h5 className="h5 fw-bold">2015 - 2016</h5>
                            </div>
                            <div className="col">
                                <h5 className="h5">IT teacher for kids</h5>
                            </div>
                            <p>Worked for a bit less than a year as a computer science and robotics teacher for kids. To help them discover how cool IT can be.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid my-4 py-4">
                <div className="row justify-content-center">
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        <h4 className="h4 fw-bold text-primary">2012 - 2018</h4>
                        <button className="btn m-0 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#epfl" aria-expanded="false" aria-controls="epfl">
                            <img alt="See more" src={chevronCompactDown}></img>
                        </button>
                    </div>
                    <div className="col mb-4">
                        <a href="https://epfl.ch"><img className="border-0" width="80" alt="Logo EPFL" src={logoEpfl}></img></a>
                    </div>
                </div>
                <div className="collapse" id="epfl">
                    <div className="container-fluid d-flex flex-column align-items-center">
                        <div className="row border-end border-primary border-3 my-2">
                            <div className="col">
                                <h5 className="h5 fw-bold">2018</h5>
                            </div>
                            <div className="col">
                                <h5 className="h5">Business Concept Course (Start-up training)</h5>
                            </div>
                            <p>Graduated from a business concept training course offered by EPFL and InnoSuisse.</p>
                        </div>
                        <div className="row border-start border-primary border-3 my-2">
                            <div className="col">
                                <h5 className="h5 fw-bold">2016 - 2017</h5>
                            </div>
                            <div className="col">
                                <h5 className="h5">Master Degree</h5>
                            </div>
                            <p>Obtained my Master of Science (Msc) in Computer Science. With a minor in image, video and sound processing.</p>
                        </div>
                        <div className="row border-end border-primary border-3 my-2">
                            <div className="col">
                                <h5 className="h5 fw-bold">2016</h5>
                            </div>
                            <div className="col">
                                <h5 className="h5">Teaching Assistant</h5>
                            </div>
                            <p>Teaching Assistant for "Information - Calcul - Communication", a course for all first-year EPFL students to be introduced about IT's basics.</p>
                        </div>
                        <div className="row border-start border-primary border-3 my-2">
                            <div className="col">
                                <h5 className="h5 fw-bold">2012 - 2016</h5>
                            </div>
                            <div className="col">
                                <h5 className="h5">Bachelor Degree</h5>
                            </div>
                            <p>Obtained my Bachelor in Computer Science. My bachelor thesis was titled: <em>The state of the art of Shadow Mapping</em>.</p>
                        </div>
                    </div>
                </div>
            </div>

            <h3 className="h3 text-primary my-4">Side projects</h3>
            <p className="lead">What follows is a list of some side projects I have done or have been implicated in somehow.</p>

            <div className="container-fluid my-4 py-4">
                <div className="row justify-content-center mb-3">
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        <h4 className="h4 fw-bold text-primary">2021</h4>
                    </div>
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        <h5 className="h5">Wedding website</h5>
                        <a href="https://salome-et-dan.ch">salome-et-dan.ch</a>
                    </div>
                </div>
                <p>Conception, design and development of a wedding website.</p>
            </div>

            <div className="container-fluid my-4 py-4">
                <div className="row justify-content-center">
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        <h4 className="h4 fw-bold text-primary">2021</h4>
                    </div>
                    <div className="col mb-4">
                        <a href="https://johuet.ch"><img className="border-0" width="70" alt="Logo Johuet" src={logoJohuet}></img></a>
                    </div>
                </div>
                <p>Conception, design and development of this website from scratch.</p>
            </div>

            <div className="container-fluid my-4 py-4">
                <div className="row justify-content-center">
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        <h4 className="h4 fw-bold text-primary">2020 - 2021</h4>
                    </div>
                    <div className="col mb-4">
                        <a href="https://corps-christ-mouvement.ch"><img className="border-0" width="80" alt="Logo CCM" src={logoCcm}></img></a>
                    </div>
                </div>
                <p>Conception, design and development of the requested website from scratch for CCM.</p>
            </div>
            
            <div className="container-fluid my-4 py-4">
                <div className="row justify-content-center">
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        <h4 className="h4 fw-bold text-primary">2020</h4>
                    </div>
                    <div className="col mb-4">
                        <a href="https://kill-the-virus.today"><img className="border-0" width="80" alt="Logo KTV" src={logoKtv}></img></a>
                    </div>
                </div>
                <p>Conception, design and development of the game webapp. Was done during the first covid sanitary lockdown.</p>
            </div>

            <div className="container-fluid my-4 py-4">
                <div className="row justify-content-center">
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        <h4 className="h4 fw-bold text-primary">2019 - 2020</h4>
                    </div>
                    <div className="col mb-4">
                        <a href="https://ntbc.ch"><img className="border-0" width="80" alt="Logo NTBC" src={logoNtbc}></img></a>
                    </div>
                </div>
                <p>Refactored the php tchoukball website to be smartphone compatible.</p>
            </div>

            <div className="container-fluid my-4 py-4">
                <div className="row justify-content-center mb-3">
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        <h4 className="h4 fw-bold text-primary">2019</h4>
                    </div>
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        <h5 className="h5">Wedding website</h5>
                    </div>
                </div>
                <p>Conception, design and development of my wedding website from scratch. (<span className="fst-italic lh-1">Sadly the website is not up anymore.</span>)<br />
                It was a website with details about the place and time of my wedding as well as a wedding gifts list and a part with pictures for guests.</p>
            </div>

            <div className="container-fluid my-4 py-4">
                <div className="row justify-content-center mb-3">
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        <h4 className="h4 fw-bold text-primary">2017</h4>
                    </div>
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        <h5 className="h5">First personal page</h5>
                    </div>
                </div>
                <p>Developed my first personal webpage exposing my personal writings (novels / poems). (<span className="fst-italic lh-1">Sadly the website is not up anymore.</span>)</p>
            </div>

            <div className="container-fluid my-4 py-4">
                <div className="row justify-content-center mb-3">
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        <h4 className="h4 fw-bold text-primary">2016</h4>
                    </div>
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        <h5 className="h5">Treasure Hunt</h5>
                    </div>
                </div>
                <p>Created an android application for a treasure hunt one time event.<br />
                The game contained enigmas to solve and a way to obtain clues when a code was entered from the real-world event.</p>
            </div>

            <div className="container-fluid my-4 py-4">
                <div className="row justify-content-center mb-3">
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        <h4 className="h4 fw-bold text-primary">2015</h4>
                    </div>
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        <h5 className="h5">JEM Lyrics</h5>
                    </div>
                </div>
                <p>Created an android application for my friends and myself to have the pdf lyrics of some songs in a viewable format for smartphones.</p>
            </div>

            <div className="container-fluid my-4 py-4">
                <div className="row justify-content-center mb-3">
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        <h4 className="h4 fw-bold text-primary">2014</h4>
                    </div>
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        <h5 className="h5">Fruit Rain</h5>
                    </div>
                </div>
                <p>Created a small android game to deepen my java / android knoweldge.<br />
                Fruits are raining down and the player has to use the touchscreen to move a basket and get them all while avoiding the bombs.</p>
            </div>
        </div>
    );
}
