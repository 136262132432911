import { Route } from 'react-router-dom';
import Header from './Header';
import About from './About';
import Footer from './Footer';
import Career from './Career';
import Contact from './Contact';
import LinksPanel from './LinksPanel';
import * as ROUTES from '../constants/routes';

const RoutingPage = () => (
    <div className="container-fluid h-100 w-100 m-0 p-0">
        <div className="container-fluid max-width-840 w-100 h-100 d-flex flex-column">
            <Header />
            <LinksPanel />
            <div className="container-fluid px-4 my-4">
              <Route exact path={ROUTES.HOME} component={About} />
              <Route path={ROUTES.CONTACT} component={Contact} />
              <Route path={ROUTES.CAREER} component={Career} />
            </div>
            <Footer />
        </div>
    </div>
);

export default RoutingPage;
