import React from 'react';
import logo from './logo.png';
import { Link, useLocation } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

const menuItems = [
    {
        name: "About me",
        link: ROUTES.HOME
    },
    {
        name: "Career",
        link: ROUTES.CAREER
    },
    {
        name: "Contact",
        link: ROUTES.CONTACT
    }
];

function Header(props) {
    
    const location = useLocation();

    const renderItem = (item) => {
        const isActive = location.pathname === item.link ? " active" : "";
        return (
            <li key={item.name} className="nav-item">
                <Link className={"nav-link" + isActive} to={item.link}>{item.name}</Link>
            </li>
        );
    }

    return (
        <header className="container m-0 p-0 mb-auto">
            <div className="container pt-4">
                <Link className="float-md-start d-none d-md-block" to="/">
                    <img alt="johuet logo" src={logo} width="48" />
                </Link>
                <div className="d-block d-md-none d-flex justify-content-center">
                    <Link to={ROUTES.HOME}>
                        <img alt="johuet logo" src={logo} width="48" />
                    </Link>
                </div>
                <ul className="nav my-nav float-md-end justify-content-center mt-1">
                    {menuItems.map(item => renderItem(item))}
                </ul>
            </div>
        </header>
    );
}

export default Header;