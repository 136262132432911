import React from 'react';

export default function About() {
    return (
        <div id="aboutCarousel" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active" data-bs-interval="30000">
                    <div className="text-center">
                        <h3 className="h3 text-primary mb-3">Who am I?</h3>
                        <p className="lead">My name is Joachim Huet, I am an IT enthousiast.</p>
                        <p>
                            Born in Chad (Africa), I grew up in a small village where electricity was scarse, 
                            let alone computers. But it didn't impede my dream to become a Software Engineer and 
                            at the age of 17, I left Africa and came to Switzerland to start my computer scientist
                            journey at <a className="text-decoration-none" href="https://epfl.ch">EPFL</a>.
                        </p>
                        <p>
                            In 2018, I obtained my <em>Master of Sciences (MSc) in Computer Science</em> with a particular 
                            interest in startups, innovation, human-computer interactions and image processing.
                        </p>
                        <p>
                            I started my carreer at <a className="text-decoration-none" href="https://baabuk.ch">Baabuk</a>, a startup 
                            and innovating shoe company, and in parallel I enrolled in the <em>Business Concept</em> training 
                            from <a className="text-decoration-none" href="https://www.innosuisse.ch">Innosuisse</a>.
                            The training was focused on <em>Entrepreunarial thinking</em> among many topics, the part 
                            on <em>Financing</em> strengthened my interest in finance and led me to my current job as a 
                            developer analyst at <a className="text-decoration-none" href="https://bcv.ch">BCV</a>.
                        </p>
                    </div>
                </div>
                <div className="carousel-item" data-bs-interval="30000">
                    <div className="text-center">
                        <h3 className="h3 text-primary mb-3">What can I do?</h3>
                        <p className="lead">
                            I would love to help you accomplish your projects!
                        </p>
                        <p>
                            From conception to realisation, working on your ideas to make something real out of them.
                            No matter how complex it looks, it is always worth trying and finding doable solutions.
                        </p>
                        <p>
                            Need a personal webpage or a website for your company? Want to refactor your current website into 
                            something brand new and responsive? <br />
                            You have some repetitive tasks that you want to automate? Trying to figure out a new process for 
                            your tasks?
                        </p>
                        <p>
                            Have a problem with your computers at home? Need some help for some maintenance or for upgrading 
                            your systems?
                        </p>
                        <p>
                            If you have something else on mind and you are not sure where to start. Just contact me.
                        </p>
                    </div>
                </div>
                <div className="carousel-item" data-bs-interval="30000">
                    <div className="text-center">
                        <h3 className="h3 text-primary mb-3">How do I work?</h3>
                        <p className="lead">
                            Simple, efficient and done according to your preferences.
                        </p>
                        <p>
                            <em>I love thinking that simpler is almost always better!</em> It's important to take the needed time to 
                            establish a proper solution and define everything that is needed before starting to work. This way we don't loose 
                            time and money on misunderstandings and will never need to start everything again from scratch.
                        </p>
                        <p>
                            I also want to offer evolutive solutions. In my opinion, a product should always be able to easily evolve according to the needs. 
                        </p>
                        <p>
                            And one of the most important part is to work together. All the problem solving and development will be done after some 
                            discussion and being a hundred percent certain that the final solution is something everyone wants and is happy with.
                        </p>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-2" style={{position: "relative"}}>
                <button className="btn carousel-control-prev" type="button" data-bs-target="#aboutCarousel"  data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <div className="carousel-indicators mb-4" style={{position: "relative"}}>
                    <li type="button" data-bs-target="#aboutCarousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Who am I?"></li>
                    <li type="button" data-bs-target="#aboutCarousel" data-bs-slide-to="1" className="" aria-label="What can I do?"></li>
                    <li type="button" data-bs-target="#aboutCarousel" data-bs-slide-to="2" className="" aria-label="How do I work?"></li>
                </div>
                <button className="btn carousel-control-next" type="button" data-bs-target="#aboutCarousel"  data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    );
}