import { faGitlab, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const LinksPanel = () => (
    <div style={{ position: 'fixed', top: '20%', right: '1rem' }}>
        <a href="https://www.linkedin.com/in/joachim-huet/" className="m-2 d-block text-primary"><FontAwesomeIcon icon={faLinkedin} size="2x" fixedWidth /></a>
        <a href="https://gitlab.com/huet.joachim" className="m-2 d-block text-primary"><FontAwesomeIcon icon={faGitlab} size="2x" fixedWidth /></a>
    </div>
)


export default LinksPanel;
